import Cookies from 'js-cookie';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import { easepick, DateTime, KbdPlugin, LockPlugin, RangePlugin } from '@easepick/bundle';
import '@/css/app.css';


class App {
    menuButton = document.getElementById('menu-button');
    menuButtonOpen = this.menuButton!.querySelector('.menu-button-open');
    menuButtonClose = this.menuButton!.querySelector('.menu-button-close');
    termButtons = document.querySelectorAll('.term');
    hotelBookerWidget = document.querySelectorAll('.hotel-booker-widget');
    
    public async init() {
        this.menuButton?.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('menu-open');
            this.toggleMenuButton();
        });

        this.termButtons?.forEach((button) => {
            button.addEventListener('click', (e) => {
                const elm:HTMLElement = e.currentTarget as HTMLElement;
                if (elm) {
                    elm.classList.add('active');
                }
            });
        });

        this.hotelBookerWidget?.forEach((booker) => {
          new HotelBooker(booker as HTMLElement);
        });
    }

    private toggleMenuButton() {
        this.menuButtonOpen!.classList.toggle('hidden');
        this.menuButtonClose!.classList.toggle('hidden');
    }
}

class AgeModal {
  ageValidateButton = document.getElementById('age-validate-button');
  modal = document.getElementById('age-modal');

  constructor() {
    this.ageValidateButton?.addEventListener('click', (e) => {
      e.preventDefault();
      this.setAgeCookie();
      this.hideAgeModal();
    });
  
    if (this.checkAgeCookie()) {
      this.hideAgeModal();
    } else {
      this.showAgeModal();
    }
  }

  private checkAgeCookie() {
    return Cookies.get('age-consent');
  }

  private setAgeCookie() {
    return Cookies.set('age-consent', '1', { expires: 7 });
  }

  private hideAgeModal() {
    if (this.modal != null) this.modal.classList.remove('open');
    document.body.classList.remove('open-age-modal');
  }

  private showAgeModal() {
    if (this.modal != null) this.modal.classList.add('open');
    document.body.classList.add('open-age-modal');
  }
  
}

class HotelBooker {
  target:HTMLElement;
  datePicker!:DatePicker;
  form:HTMLFormElement|null;

  constructor(target:HTMLElement) {
    this.target = target;
    this.form = target.querySelector('form');
    const language = document.documentElement.lang;
    const datePickerElm:HTMLElement|null = target.querySelector('.datepicker');

    // Init datePicker
    if (datePickerElm) {
      this.datePicker = new DatePicker(datePickerElm, language);
    };

    if (this.form) {
      this.form.addEventListener('submit', (event) => {
        event.preventDefault();

        const e:any = event;

        if (this.datePicker) {
          // console.log(this.datePicker.getDate());
          const date = this.datePicker.getDate();
          if (date[0] && date[1]) {
            this.submit(e.target.action, date[0], date[1], language);
          }
        }
      });
    }
  }

  private submit(action:String, start:Date, end:Date, language = 'nl') {
    if (this.form) {
      const url = action + '?lang=' + language + '&Arrival=' + this.formatDate(start) + "&Departure=" + this.formatDate(end);
      // window.location.assign(url);
      window.open(url, '_blank');
      // console.log(action + '?lang=' + language + '&Arrival=' + this.formatDate(start) + "&Departure=" + this.formatDate(end));
    }
  }

  private formatDate(date:Date) {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  }
}

class DatePicker {
  target:HTMLElement;
  input:HTMLInputElement;
  selection:HTMLElement;
  start!:Date;
  end!:Date;
  language:string;
  arrow:string = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
<path d="M17.2071 8.96602C17.5976 8.57549 17.5976 7.94233 17.2071 7.5518L10.8431 1.18784C10.4526 0.797319 9.81946 0.797319 9.42893 1.18784C9.03841 1.57837 9.03841 2.21153 9.42893 2.60206L15.0858 8.25891L9.42893 13.9158C9.03841 14.3063 9.03841 14.9395 9.42893 15.33C9.81946 15.7205 10.4526 15.7205 10.8431 15.33L17.2071 8.96602ZM0.5 9.25891H16.5V7.25891H0.5V9.25891Z" fill="#AC9C52"/>
</svg>`;
  
  constructor(target:HTMLElement, language:string) {
    this.target = target;
    this.target.innerHTML = ''; // Clear div
    this.language = language;

    this.selection = document.createElement('div')
    this.selection.className = 'datepicker-selection';
    this.selection.innerHTML = 'Kies een datum';
    this.target.appendChild(this.selection);

    this.input = document.createElement('input')
    this.input.type = 'text';
    this.input.className = 'datepicker-input';
    this.target.appendChild(this.input);

    // const today:Date = Date.now();
    // this.start = today;
    // this.end = today.getDate()+1;
 
    // Create date picker
    const picker = new easepick.create({
        element: this.input,
        css: [
          'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css'
        ],
        lang: this.language,
        zIndex: 10,
        grid: 2,
        calendars: 2,
        plugins: [
          RangePlugin,
          LockPlugin,
          KbdPlugin
          ],
        RangePlugin: {
          tooltipNumber(num) {
            return num - 1;
          },
          locale: this.getLocale(this.language),
        },
        LockPlugin: {
          minDate: new Date(),
          minDays: 2,
          inseparable: true
        },
        setup: (picker) => {
          picker.on('select', (e) => {
            const { start, end } = e.detail;
            this.start = start;
            this.end = end;

            this.selection.innerHTML = `<span class="date date-start">${start.getDate()}/${start.getMonth()+1}/${start.getFullYear()}</span>${this.arrow}<span class="date date-end">${end.getDate()}/${end.getMonth()+1}/${end.getFullYear()}</span>`;
            // const dt: DateTime = easepick.DateTime;
            // this.selection.innerHTML = `${new dt(start.toTimeString(), 'DD-MM-YYYY')} - ${new dt(end.toTimeString(), 'DD-MM-YYYY')}`;
          });
        },
    });
  }

  private getLocale(language:string) {
    switch(language) {
      case 'nl':
        return {
          one: 'nacht',
          other: 'nachten',
        }
      case 'fr':
        return {
          one: 'nuit',
          other: 'nuits',
        }
      case 'de':
        return {
          one: 'Nacht',
          other: 'Nächte',
        }
      case 'en':
      default:
        return {
          one: 'night',
          other: 'nights',
        }
    }
  }

  public getDate() {
    return [this.start, this.end];
  }
}

/* Check for sticky */
// const block = document.querySelector('.block-section-menu') as HTMLElement;
// const observer:IntersectionObserver = new IntersectionObserver(
//     ([e]) => {
//         console.log('IntersectionObserver', e)
//         e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
//     },
//     {
//         rootMargin: "0px 0px 0px 0px",
//         threshold: 0,
//     }
// );
// observer.observe(block);

/* Accordion */
const accordionItems = document.querySelectorAll(".accordion-item");

accordionItems.forEach(item =>
  item.addEventListener("click", () => {
    const isItemOpen = item.classList.contains("open");
    accordionItems.forEach(item => item.classList.remove("open"));
    if (!isItemOpen) {
      item.classList.toggle("open");
    }
  })
);

/* Slider */
interface breakPoint {
  [index:number]: Object;
}

let breakPoints:any = {};
for (let index = 2; index <= 20; index++) {
  const breakPoint = (320 * index) + (30 * (index - 1)) + 40;
  breakPoints[breakPoint] = { perPage: index };
}

const slides = document.querySelectorAll<HTMLElement>('.splide');
if (slides) {
  for ( var i = 0; i < slides.length; i++ ) {
    var splide = new Splide( slides[ i ] , {
        autoHeight: true,
        autoWidth: true,
        arrows: false,
        gap: 20,
        padding: { left: 20, right: 20 },
        mediaQuery: 'min',
        breakpoints: breakPoints
    } );

    splide.on( 'overflow', function ( isOverflow ) {
        splide.options = {
            autoHeight: true,
            drag      : isOverflow,
        };

        splide.go(0);
    });

    splide.mount();
  }
}

/* Cookie Consent */
const cookieConsentConfig:CookieConsent.CookieConsentConfig = {
  onFirstConsent: ({cookie}) => {
      window?.dataLayer?.push({ 'event': 'client-consent-update' });
  },
  onChange: ({cookie}) => {
      window?.dataLayer?.push({ 'event': 'client-consent-update' });
  },
  guiOptions: {
      consentModal: {
          layout: "box inline",
          position: "bottom right",
          equalWeightButtons: true,
          flipButtons: false
      },
      preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false
      }
  },
  categories: {
      necessary: {
          readOnly: true
      },
      functionality: {},
      analytics: {},
      marketing: {}
  },
  language: {
      default: "nl",
      autoDetect: "document",
      translations: {
          en: {
              consentModal: {
                  title: "Hello traveller, it's cookie time!",
                  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
                  acceptAllBtn: "Accept all",
                  acceptNecessaryBtn: "Reject all",
                  showPreferencesBtn: "Manage preferences",
                  footer: "<a href=\"#link\">Privacy Policy</a>\n<a href=\"#link\">Terms and conditions</a>"
              },
              preferencesModal: {
                  title: "Consent Preferences Center",
                  acceptAllBtn: "Accept all",
                  acceptNecessaryBtn: "Reject all",
                  savePreferencesBtn: "Save preferences",
                  closeIconLabel: "Close modal",
                  serviceCounterLabel: "Service|Services",
                  sections: [
                      {
                          title: "Cookie Usage",
                          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                      },
                      {
                          title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                          linkedCategory: "necessary"
                      },
                      {
                          title: "Analytics Cookies",
                          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                          linkedCategory: "analytics"
                      },
                      {
                          title: "Advertisement Cookies",
                          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                          linkedCategory: "marketing"
                      }
                  ]
              }
          },
          nl: {
              consentModal: {
                  title: "Het Anker gebruikt cookies om je surfgedrag te verbeteren.",
                  description: "Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Wilt u liever zelf kiezen welke cookies u aanvaardt? Klik op ‘Cookievoorkeuren beheren’. Meer info leest u in het privacy- en cookiebeleid van hetanker.be",
                  acceptAllBtn: "Alles aanvaarden",
                  acceptNecessaryBtn: "Alles weigeren",
                  showPreferencesBtn: "Voorkeuren aanpassen",
                  footer: "<a href=\"#link\">Privacybeleid</a>\n<a href=\"#link\">Algemende voorwaarden</a>"
              },
              preferencesModal: {
                  title: "Cookievoorkeuren",
                  acceptAllBtn: "Alles aanvaarden",
                  acceptNecessaryBtn: "Alles weigeren",
                  savePreferencesBtn: "Voorkeuren opslaan",
                  closeIconLabel: "Venster sluiten",
                  serviceCounterLabel: "Service|Services",
                  sections: [
                      {
                          title: "Cookiegebruik",
                          description: "Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Wilt u liever zelf kiezen welke cookies u aanvaardt? Klik op ‘Cookievoorkeuren beheren’. Meer info leest u in het privacy- en cookiebeleid van hetanker.be"
                      },
                      {
                          title: "Noodzakelijke cookies <span class=\"pm__badge\">Steeds ingeschakeld</span>",
                          description: "Deze cookies zijn vereist om de website goed te laten werken en zorgen ervoor dat uw cookievoorkeuren worden bewaard.",
                          linkedCategory: "necessary"
                      },
                      {
                          title: "Analytische cookies",
                          description: "Deze cookies meten het aantal bezoekers op de website en geven een algemeen beeld van uw bezoek. Alle verzamelde gegevens zijn anoniem.",
                          linkedCategory: "analytics"
                      },
                      {
                          title: "Media cookies",
                          description: "Media cookies zijn een vorm van marketing cookies. Deze cookies worden gebruikt om de bezoekers over websites heen te volgen. De bedoeling is om advertenties weer te geven die relevant zijn voor de individuele gebruiker en daardoor waardevoller zijn voor uitgevers en externe adverteerders.",
                          linkedCategory: "marketing"
                      }
                  ]
              },
              
          }
      }
  }
};

CookieConsent.run(cookieConsentConfig);

let app = new App();
app.init();

let ageModal = new AgeModal();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}